<template>
   <div class="panel">

    <router-view />

  </div>

</template>

<script>

export default {
    name: "SetupStudent",
    components: {
    },    
    props: {
    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
        
        this.$store.dispatch('user/setUserType','student');
    },
    computed: {
       
    },
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
}

.left {
    width:38%;
    display: flex;
    align-items: center;
}


.center {
    width:40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right {
    width:15%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    padding-top: calc(240px / var(--scale) ); 
    /* border:1px solid red; */
    /* justify-content: center; */
}

.prevbutton {
    margin-top:40px;
    margin-right:-5px;
}

</style>