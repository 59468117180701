<template>
  <div class="panel">
    

      <div class="option">
        <img :src="`./static/images/keuze-leerling.png`" />
        <router-link to="setup/student">
          <app-button ref="studentbutton" class="selectbutton" color="purple" bevel="true" caption="leerling"/>
        </router-link>
      </div>
      <div class="option">
        <img :src="`./static/images/keuze-begeleider.png`" />
        <router-link to="setup/begeleider">
          <app-button ref="begeleiderbutton" class="selectbutton" color="purple" bevel="true" caption="begeleider" />
        </router-link>
      </div>


  </div>
</template>

<script>
  import AppButton from  "@/components/ui/AppButton.vue";

  export default {
   // name: 'activity-setup',
    data() {
      return {
        // currentSelection : null
      }
    },
    components: {
      AppButton
    },
    methods: {
    },
    mounted() {
      this.$store.dispatch('user/reset',false);      
      this.$store.dispatch('setFooterVisibility',false);   
      

      this.$store.dispatch("setHeaderContent", {caption: "Aanmelden"} );

    }
  }
</script>

<style scoped>
  
.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  .selectbutton {
    /* margin: 0 100px; */
    margin-top: 20px;
  }

  .option {
    /* border:1px solid red; */
    width:  calc(197px / var(--scale));
    height:  calc(390px / var(--scale));
    display: flex;
    flex-direction: column;
    margin: 0 80px;
    /* display:flex; */
    /* justify-content: flex-end; */
  }

  .option img {
    margin-top: auto;
    width:100%;
  }

</style>