<template>
  <div class="page">
    
    <div class="content" >
          <error-banner color="yellow" ref="errorbanner" />

          <div class="panel-left">
                
            <div class="instruction">{{$t('instruction-roomname')}}</div>

            <input v-for="index in this.codelength" 
              :key="index" 
              :ref="`digit-${index}`"
              class="inputfield"
              maxlength="1" 
              v-model="digits[index]"
              @input="handleInputChange(index)"/>
          <!-- <div v-if="errormessage" class="errormessage">{{errormessage}}</div> -->
          </div>
          <div class="panel-buttons">
              <app-button class="button" ref="nextbutton" color="purple" bevel="true" :caption="$t('button-next')"   @click="handleSubmit" />
              <app-button class="button" ref="prevbutton" color="purple" bevel="false" :caption="$t('button-prev')"   @click="handlePrev" />
          </div>
      </div>

  </div>
</template>

<script>
  import AppButton from  "@/components/ui/AppButton.vue";
  import ErrorBanner from  "@/components/ui/ErrorBanner.vue";

  //import SocketClientHelper from "@/helpers/SocketClientHelper"
  import axios from 'axios'

  export default {
   // name: 'activity-setup',
    data() {
      return {
        roomname : null,
        codelength: 6,
        digits:[],
        // errormessage:''
      }
    },
    components: {
      AppButton,
      ErrorBanner
    },
    computed: {
      // showerror() {

      // },
      //...mapState(['errormessage'])
    },
    methods: {
      // isNumeric(n) {        
      //     return !isNaN(parseFloat(n)) && isFinite(n);
      // },
      isAlphaNumeric(v) {
        // console.log(v + ":" + (v ==  v.match(/^[a-z0-9]+$/i)) )
        return (v == v.match(/^[a-z0-9]+$/i));
      },
      handleInputChange( index) {
        // get current element
        // console.log(index)
        this.$refs.errorbanner.clearError();
        
        // BVB 15 juli 2024 shopID is now alphanumeric
         // also rewrote check because of alphanumeric keyboard handling
         // in mobile chrome

        //let v = this.digits[index]; 
        let v = this.$refs['digit-' + index][0].value;
        // console.log(index + ":" + v)
        if (this.isAlphaNumeric(v)) {
            //this.digits = this.digits + v;
            this.digits[ index ] = v;
            // value ok, go to next field
            if (index < 6) {
              // this.$refs.digit[index].focus();
              this.$refs['digit-' + (index + 1)][0].focus();
            }
            
            // console.log(this.digits)
        }
                
        const code = this.digits.join('');
        // console.log(code)

        // let input_ok = this.digits.every(this.isAlphaNumeric) && this.digits.length > this.codelength ;
        // let input_ok = this.isAlphaNumeric(this.digits) && this.digits.length === this.codelength ;
        
        let input_ok = this.isAlphaNumeric(code) && code.length === this.codelength ;

        this.$refs.nextbutton.enabled( input_ok );
        //this.roomname = target.value;
      },
      // handleInputChange( index) {
      //   // get current element
      //   this.$refs.errorbanner.clearError();
      //   let v = this.digits[index]; 
      //   if (this.isNumeric(v)) {
      //       // value ok, go to next field
      //       if (index < 6) {
      //         this.$refs.digit[index].focus();
      //       }
      //   }
      //   let input_ok = this.digits.every(this.isNumeric) && this.digits.length > this.codelength ;

      //   this.$refs.nextbutton.enabled( input_ok );
      //   //this.roomname = target.value;
      // },
      handleSubmit() {
          this.roomname = '';
          for(let i=0; i < this.digits.length; i++) {
            if ( this.digits[i] ) {
              this.roomname += '' + this.digits[i];
            }
          }

          // BVB 15 juli 2024 internally use lowercase shopID
          this.roomname  = this.roomname.toLowerCase();

          this.$store.dispatch('user/setRoomname',this.roomname);       
          
          // TODO: check for errors here
         // this.$store.dispatch('socket/connectToSocket');    

          //this.scm.connect(this.$store.getters['user/isMaster'], this.roomname);

          this.$socket.client.io.opts.query = "isMaster=" + this.$store.getters['user/isMaster'] + "&roomname="+ this.roomname + "&version=1";
          this.$socket.client.open();  

          // see if we have game settings on the server
          let endpoint = process.env.VUE_APP_API + "game-info/" + this.roomname;

          axios.get(endpoint)
          .then(response => {

              this.$store.dispatch("gamesettings/info", response.data.decoded);
              this.$store.dispatch("gamesettings/code", response.data.code);

              // console.log(response.data)

              if (response.data.appState.action) {
                this.$store.dispatch("game/setGameStatus", response.data.appState.action );
              }

              if (response.data.appState.round) {
                this.$store.dispatch("game/setCurrentRound",response.data.appState.round  );
              }

              // TODO: handle dimmed screens and other app states as well
              this.$store.dispatch("game/appState", response.data.appState);

              this.goNextPage();

          })
          .catch(error => {
              //commit('UPDATE_STATUS', 'pending-save');
              if (error.response) {
                      this.$refs.errorbanner.showErrorString(error.response.data.message);
              }
              else {
                  this.$refs.errorbanner.showErrorString(error);
              }
              // this.goNextPage();          
          })
      }, 
      handlePrev() {
          this.$router.push('/');
      },
      goNextPage() {
          if (this.$store.getters['user/isMaster']) {
            switch(this.$store.getters["game/status"]){
              case "started": {
                this.$router.push('/begeleider/map/start-game');
                break;
              }
              case "endgame": {
                this.$router.push('/begeleider/endgame/instructions');
                break;
              }
              case "finished": {
                this.$router.push('/begeleider/endgame/leaderboard');
                break;
              }
              default: {
                // setup or no status at all
                this.$router.push('time');
                break;
              }
            }
          }
          else {
            this.$router.push('group');
          }        
      }
    },
    mounted() {

      this.$refs.nextbutton.enabled(false);
      this.$store.dispatch('setFooterVisibility',false);      

      if (this.$socket.client.connected) {
            this.$socket.client.close();
      }

    }
  }
</script>

<style scoped>
  
.page {
   background-color: var(--green-middle-color);
    display: flex;
    /* flex-direction: row; */
    justify-content: center; 
    align-items: flex-start;
}


.content {
    position: relative;
    margin-top: 60px;
    width:100vw;
    height: 90vh;
    /* background-position: bottom; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: center; */
}

.panel-left {
  width: 73vw;
  height: 75vh;

  margin-top: 115px;
  margin-left:150px;
  
  /* font-family:'Fira Sans Book';
  font-size: calc(30px / var(--scale));
  line-height: calc(43px / var(--scale));
  color: var(--light-text-color); */
}


.panel-buttons {
    height: 80vh;
    width:27vw;
    margin-top:160px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.instruction {
  font-family:'Fira Sans Bold';
  font-size: calc(35px / var(--scale));
  color: var(--yellow-dark-color);
  margin:10px 0;
}
.inputfield {
    height: calc(55px / var(--scale));
    width: calc(55px / var(--scale));
    padding: 0 5px;
    font-family: 'Fira Sans SemiBold';
    font-size: calc(30px / var(--scale));
    /* font-weight: bold; */
    margin-right: 10px;
    padding:12px;
}

.panel-buttons .button {
  margin-bottom: 50px;
  margin-right:60px;
  margin-left: 50px;
}


</style>