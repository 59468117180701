<template>
  <div class="page">
    
    <div class="content" >
          <error-banner color="yellow" ref="errorbanner" />

          <div class="panel-left">
                
            {{this.$route.params.roomname}}
            <br/>{{this.$route.params.user}}
            <br/>{{this.$route.params.groupname}}

          </div>

    </div>
  </div>
</template>

<script>
  //import AppButton from  "@/components/ui/AppButton.vue";
  import ErrorBanner from  "@/components/ui/ErrorBanner.vue";

  import axios from 'axios'

  export default {
   // name: 'activity-setup',
    data() {
      return {
        // currentSelection : null
      }
    },
    components: {
      //AppButton
      ErrorBanner
    },
    methods: {
      // this page gets called directly via the url by the VWNW app:
      // {{ grondwetloop_url }}/#/vwnw/

      restoreAppstate(data) {
          // console.log("restore appstate");
          // console.log(data)
          this.$store.dispatch("gamesettings/info", data.decoded);
          this.$store.dispatch("gamesettings/code", data.code);

          if (data.appState.action) {
            this.$store.dispatch("game/setGameStatus", data.appState.action );
          }

          if (data.appState.round) {
            this.$store.dispatch("game/setCurrentRound",data.appState.round  );
          }

          // TODO: handle dimmed screens and other app states as well
          this.$store.dispatch("game/appState", data.appState);        
      },
      completeSetup() {
          this.$socket.client.io.opts.query = "isMaster=" + this.$store.getters['user/isMaster'] + "&roomname="+ this.$store.getters['user/roomname'] + "&version=1";
          this.$socket.client.open();  

          // console.log("complete setup")
          if (this.$store.getters['user/isMaster']) {
              this.handleMasterRoute();
          }
          else {
              let groupname = this.$route.params.groupname;

              this.$store.dispatch('user/setGroupname',groupname)
              .then(() => {
                  this.handleStudentRoute();
              })
              .catch(error => {
                  this.handleError(error);
                  this.handleStudentRoute();
              });                  
          }    
      },
      handleMasterRoute() {
          // console.log("master route, status:" + this.$store.getters["game/status"])
          switch(this.$store.getters["game/status"]){
            case "started": {
              this.$router.push('/begeleider/map/start-game');
              break;
            }
            case "endgame": {
              this.$router.push('/begeleider/endgame/instructions');
              break;
            }
            case "finished": {
              this.$router.push('/begeleider/endgame/leaderboard');
              break;
            }
            case "setup": {
              console.log("setup ")
              this.$router.push('/setup/begeleider/time');
              break;
            }
            default: {
              // setup or no status at all
              console.log("setup ")
              this.$router.push('/setup/begeleider/time');
              break;
            }
          }
      },
      handleStudentRoute() {
          console.log("student route, status:" + this.$store.getters["game/status"])
          switch(this.$store.getters["game/status"]){
            case "setup": {
              this.$router.push('/student/instructions');
              break;
            }
            case "started": {
              console.log("START from VWNW setup")
              // BVB 28 02 2023 prevent error
              // NavigationDuplicated: Avoided redundant navigation to current location
              // this occurs because this socket event listener exists in multiple locations, which is clearly
              // not a good idea
              // I left the listeners as is and just check if we are already at the route path
              // A better option would be to centralize the socket listeners, but that would be a 
              // substantial rewrite
              if (this.$route.path !='/student/map/start' ){
                this.$router.push('/student/map/start')
              }
              break;
            }
            case "endgame": {
              // BVB 28 02 2023 prevent error
              // NavigationDuplicated: Avoided redundant navigation to current location
              // this occurs because this socket event listener exists in multiple locations, which is clearly
              // not a good idea
              // I left the listeners as is and just check if we are already at the route path
              // A better option would be to centralize the socket listeners, but that would be a 
              // substantial rewrite
              if (this.$route.path !='/student/endgame/instructions' ){
                this.$router.push('/student/endgame/instructions')
              }
              break;
            }
            case "finished": {
              this.$router.push('/student/endgame/shield');
              break;
            }
          }
      },
      handleError(error) {
          if (error.response) {
              this.$refs.errorbanner.showErrorString(error.response.data.message);
          }
          else if (error.status) {
              this.$refs.errorbanner.showErrorString(error.status);
          }
          else {
               this.$refs.errorbanner.showErrorString("geen verbinding met de server");
          }
      }

    },
    mounted() {

      if (this.$route.params.user && this.$route.params.roomname) {

          let user = this.$route.params.user;
          let roomname = this.$route.params.roomname;

          // save user and roomname to store
          this.$store.dispatch('user/setUserType', user);
          this.$store.dispatch('user/setRoomname', roomname);       
          this.$store.dispatch('user/setVWNWmode', true);       
            
          //this.completeSetup();

          // create the session, if it already exists we will receive some data
          let endpoint = process.env.VUE_APP_API + "sessions/" + this.$store.getters['user/roomname'] + "/create";
          axios.post(endpoint)
          .then((response) => {
              if (response.data.code === "000000") {
                // this is a new session
                this.completeSetup();
              }
              else {
                // this is an existing session, get more info
                let endpoint = process.env.VUE_APP_API + "game-info/" + this.$store.getters['user/roomname'];

                axios.get(endpoint)
                .then(response => {
                    this.restoreAppstate(response.data);    
                    this.completeSetup();
                })
                .catch(error => {
                    // something went wrong and we could not get anything. Start anyway
                    this.completeSetup();
                    this.handleError(error);
                });
              }
          })
          .catch(error => {
              // something went wrong and we could not get anything. Start anyway
              this.completeSetup();
              this.handleError(error);
          });

          /*
          
          // see if we have game settings on the server
          let endpoint = process.env.VUE_APP_API + "game-info/" + this.$store.getters['user/roomname'];

          axios.get(endpoint)
          .then(response => {
              this.restoreAppstate(response.data);    
              this.completeSetup();
          })
          .catch(error => {
              if (error.response) {
                if (error.response.data.body.reason === "INVALID_SHOP_OPTION_ID") {
                // session unknown, create the session now

                  endpoint = process.env.VUE_APP_API + "sessions/" + this.$store.getters['user/roomname'] + "/create";
                  axios.post(endpoint)
                  .then(() => {
                      this.completeSetup();
                  })
                  .catch(error => {
                      this.completeSetup();
                      this.handleError(error);
                  });
                }
                else {
                  this.handleError(error);
                  this.completeSetup();
                }
              }
              else {
                  this.handleError(error);
                  this.completeSetup();
              }


              if (error.response.data.body.reason === "INVALID_SHOP_OPTION_ID") {
                // session unknown, create the session now

                endpoint = process.env.VUE_APP_API + "sessions/" + this.$store.getters['user/roomname'] + "/create";
                axios.post(endpoint)
                .then(() => {
                    this.completeSetup();
                })
                .catch(error => {
                    this.handleError(error);
                });
              }
              else {
                // some other problem
                this.handleError(error);
                this.completeSetup();
              }
          }) */
      }
      

      //this.$store.dispatch('user/reset',false);      
      this.$store.dispatch('setFooterVisibility',false);   
      this.$store.dispatch("setHeaderContent", {caption: "welkom in vwnw"} );

    }
  }
</script>

<style scoped>
  
.page {
   background-color: var(--green-middle-color);
    display: flex;
    /* flex-direction: row; */
    justify-content: center; 
    align-items: flex-start;
}


.content {
    position: relative;
    margin-top: 60px;
    width:100vw;
    height: 90vh;
    /* background-position: bottom; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: center; */
}

.panel-left {
  width: 73vw;
  height: 75vh;

  margin-top: 115px;
  margin-left:150px;
  
  /* font-family:'Fira Sans Book';
  font-size: calc(30px / var(--scale));
  line-height: calc(43px / var(--scale));
  color: var(--light-text-color); */
}

</style>