<template>
   <div class="panel">

        <router-view />

  </div>

</template>

<script>

export default {
    name: "SetupScreen",
    components: {
    },    
    props: {
    },
    data() {
        return {
            // state: 'time'
        };
    },
    methods: {
        handleNext() {
            // if (this.state === 'room') {
            //     this.state = 'time';
            // }
            // else if (this.state === 'time') {
            //     // start endgame for now
            //     this.state = 'level';
            // }
            // else if (this.state === 'level') {
            //     this.state = 'rounds';
            // }
            // else if (this.state === 'rounds') {
            //     this.$emit("next")                
            // }
        },
        handlePrev() {
            // if (this.state === 'time') {
            //     this.$emit("prev");
            // }
            // else if (this.state === 'level') {
            //     this.state = 'time';
            // }
            // if (this.state === 'rounds') {
            //     this.state = 'level';              
            // }
        }        
    },
    mounted() {
        this.$store.dispatch('user/setUserType','begeleider');           
    },
    computed: {
       
    },
};
</script>


<style scoped>

.panel {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
}

.left {
    width:38%;
    display: flex;
    align-items: center;
}


.center {
    width:40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right {
    width:15%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    padding-top: calc(240px / var(--scale) ); 
    /* border:1px solid red; */
    /* justify-content: center; */
}

.prevbutton {
    margin-top:40px;
    margin-right:-5px;
}

</style>